<template>
    <v-chart class="satiins-chart" :option="option" :id="id" autoresize />
</template>

<script>
import "@/assets/styles.css";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart, LineChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent,
    DatasetComponent,
    GridComponent,
    DataZoomComponent
} from "echarts/components";
import VChart from "vue-echarts";
import { defineComponent } from "vue";

use([
    CanvasRenderer,
    PieChart,
    BarChart,
    LineChart,
    DatasetComponent,
    GridComponent,
    DataZoomComponent,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent
]);

export default defineComponent({
    name: "eChart",
    components: {
        VChart
    },
    props: {
        id: String,
        chartOptions: Object
    },
    computed: {
        option() {
            return this.chartOptions;
        }
    }
});
</script>
